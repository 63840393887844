import React from "react";

function Gdpr() {
  return (
    <div className="add">
      <div className="content">
        {" "}
        <h1
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          Information om behandling av personuppgifter
        </h1>
        <div className="containerTextBorder">
          <p>
            <i>
              Sedan 25 maj 2018 gäller GDPR (dataskyddsförordningen/General Data
              Protection Regulation) i hela EU och reglerar hantering av
              registrerade personuppgifter. Här kan du läsa om hur vi hanterar
              dina personuppgifter. För att läsa mer om GDPR hänvisas till
              Integritetsskyddsmyndighetens{" "}
            </i>
            <a href="https://www.imy.se/" target="_blank" rel="no noreferrer">
              {" "}
              hemsida.
            </a>
            <br />
            <br />
            <h3>
              Hur Stiftelsen Hedberg och Norlins Stiftelse hanterar dina
              personuppgifter
            </h3>
            När du inleder en ansökan registeras de personuppgifter du anger
            automatiskt i stiftelsens databas. Utöver att personuppgifter sparas
            digitals så sparas även den fysiska versionen av ansökan som skickas
            in via post. De uppgifter som sparas är de som du själv anger i
            ansökan. Vilken omfattar exempelvis: namn (huvudsökande, medsökande
            och prefekt), adress, epost, telefonnummer, information om aktuell
            institution och kontaktuppgifter avseende denna. Tillgång till
            personuppgifterna har styrelserna för Hedbergs och Norlins stiftelse
            samt dessa stiftelsers gemensamma webbadministratör och
            personuppgiftsansvarig är styrelsen för Hedbergs stiftelse.
            Uppgifterna sparas i upp till tio år, men beslut kan fattas att
            radera uppgifterna tidigare (detta avser både fysiskt och digitalt
            arkiv). På stiftelsens hemsida publiceras en lista över tidigare
            pristagare, utdelat anslag samt namn på forskningsprojektet. Denna
            information är offentlig och sparas på obestämd tid. <br />
            <br />
            Du har rätt att få information om vilka personuppgifter som
            behandlas om dig, få felaktiga uppgifter rättade, få dina
            personuppgifter raderade om uppgifterna inte längre är nödvändiga,
            få information om hur länge vi kommer att lagra dina
            personuppgifter. Du kan även lämna in klagomål till
            Integritetsskyddsmyndigheten om du har synpunkter på hur Stiftelsen
            Hedbergs eller Norlins Stiftelse hanterar dina personuppgifter. Vid
            särskilda omständigheter som t.ex. om huvudsökande eller medsökande
            har skyddad identitet vänligen kontakta webbadministratör för att
            detta ska kunna hanteras i enlighet med aktuell lagstiftning och
            individens önskemål.
            <br /> <br />
            Personuppgifter sparas av Stiftelsen för att underlätta
            ansökningsproceduren för tidigare sökande, för att kunna utvärdera
            de redovisningar som skickas in av tidigare anslagsmottagar samt för
            internt förbättringsarbete.
            <i>
              {" "}
              Om något av informationen ovan är otydlig eller om du har andra
              frågor eller funderingar kan du vända dig till webbadministratör
              Axel Persson Wieslander på support@hedbergsstiftelse.se. Övriga
              kontaktuppgifter hittar du under fliken "Kontakt" i menyn.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Gdpr;
