import React, { useState, useContext } from "react";
import InfoHover from "../components/InfoHover";
import { AuthContext } from "../context/authContext";

const PersonalDetails = ({
  nextStep,
  handleChange,
  prevStep,
  values,
  postStateToDb,
  handleChangeFile,
  setStep,
  coauthor,
  setState,
  handleListChange,
  handleListRemove,
}) => {
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: "",
    title: "",
  });

  const removeCoauthor = (input) => (e) => {
    e.preventDefault();
    let coauthor = values.coauthor.filter(function (person) {
      return person !== input;
    });
    handleListRemove(coauthor, "coauthor");
    postStateToDb();

    setState();
  };

  const firstname = currentUser.firstname;
  const lastname = currentUser.lastname;

  const postToDb = (e) => {
    e.preventDefault();
    postStateToDb();
  };
  const addCoauthor = (e) => {
    e.preventDefault();
    handleListChange(formData, "coauthor");
    postStateToDb();
    setFormData({
      name: "",
      title: "",
    });
  };

  const handleChangeForm = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };
  const renderList = values?.coauthor?.map((item, index) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
      }}
      key={index}
    >
      <div>
        {item.title} {item.name}
      </div>
      <button onClick={removeCoauthor(item)}>X</button>
    </div>
  ));

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="add" style={{ display: "block" }}>
      <div
        className="inputDetails"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div
          className="inputContact"
          style={{ marginRight: "75px", flex: "10" }}
        >
          <h1>Kontaktuppgifter</h1>
          <div className="inputContainer">
            <div id="content" className="content">
              <div className="label">
                <label>
                  <b>Förnamn</b>:
                </label>
                <InfoHover
                  origin={
                    "Förnamn / tilltalsnamn, enligt Folkbokföringsregistret"
                  }
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Förnamn"
                value={firstname}
                onChange={handleChange("firstname")}
                disabled
              />
              <div className="label">
                <label>
                  <b>Efternamn:</b>
                </label>
                <InfoHover
                  origin={"Efternamn enligt Folkbokföringsregistret."}
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Efternamn"
                value={lastname}
                onChange={handleChange("lastname")}
                disabled
              />
              <div className="label">
                <label>
                  <b>Yrkestitel:</b>
                </label>
                <InfoHover
                  origin={
                    "Nuvarande yrkestitel, t.ex. professor, docent eller doktorand, etc."
                  }
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Titel"
                value={values.jobtitle}
                onChange={handleChange("jobtitle")}
              />
              <div className="label">
                <label>
                  <b>Telefon:</b>
                </label>
                <InfoHover
                  origin={
                    "Ange telefonnummer till din arbetsplats. Om du inte har ett arbetsplatsnummer, ange ditt mobilnummer. Ange endast siffror, inga mellanslag eller bindestreck. Exempel: 0701234567"
                  }
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Telefon nummer"
                value={values.phone}
                onChange={handleChange("phone")}
              />
              <div className="label">
                <label>
                  <b>Ansvarig prefekt:</b>
                </label>
                <InfoHover
                  origin={"Ange namn och titel på prefekt eller motsvarande."}
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Namn samt titel"
                value={values.prefect}
                onChange={handleChange("prefect")}
              />
            </div>

            <div
              style={{ marginLeft: "20px" }}
              id="content2"
              className="content"
            >
              <div className="label">
                <label>
                  <b>Universitet:</b>
                </label>
                <InfoHover
                  origin={
                    "OBS. Utdelning från stiftelsen sker endast till Svenska lärosäten. Utdelning sker ej till sjukhus, regioner eller utländska lärosäten, inklusive Norden."
                  }
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Lärosätets namn"
                value={values.university}
                onChange={handleChange("university")}
              />
              <div className="label">
                <label>
                  <b>Institution:</b>
                </label>
                <InfoHover
                  origin={
                    "Namn på den Institution du tillhör. Om du är är knuten till flera Institutioner, ange den Institution där aktuellt forskningsprojekt kommer genomföras."
                  }
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Namn på Institution"
                value={values.institution}
                onChange={handleChange("institution")}
              />
              <div className="label">
                <label>
                  <b>Gatuadress:</b>
                </label>
                <InfoHover
                  origin={
                    "Ange gatuadress till ditt lärosäte/din institution. Ange endast gatuadress, inga postboxar eller dylikt."
                  }
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Gatuadress "
                value={values.address}
                onChange={handleChange("address")}
              />
              <div className="label">
                <label>
                  <b>Postnummer & Stad:</b>
                </label>
                <InfoHover
                  origin={"Avseende Universitet/institution"}
                ></InfoHover>
              </div>
              <input
                type="text"
                placeholder="Postnummer samt stad"
                value={values.city}
                onChange={handleChange("city")}
              />
              <p style={{ fontSize: "14px" }}>
                <em>Observera: </em>Adress avser din <b>arbetsplats</b> adress,
                inte din hemadress. Fälten Universitet respektive Institution
                kan användas för motsvarande adressrader i andra typer av
                organisationer.
              </p>
            </div>
          </div>
        </div>
        {/* lägg till MEDSÖKANDE börjar här */}
        <div className="menu">
          <div
            className="item"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <div className="label">
              <h1> Lägg till medsökande:</h1>
            </div>
            <span>
              Skriv in namn samt titel på medsökande. Kontrollera att samtliga
              medsökande finns med i listan nederst och har registrerats
              korrekt.
            </span>
            <form>
              <div className="label">
                <label>Namn:</label>
              </div>
              <input
                type="text"
                placeholder="Namn"
                value={formData.name}
                onChange={handleChangeForm("name")}
                style={{ marginBottom: "7px" }}
              />
              <div className="label">
                <label>Titel:</label>
              </div>
              <input
                type="text"
                placeholder="Titel"
                value={formData.title}
                onChange={handleChangeForm("title")}
              />
            </form>

            <span style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="applicationbutton"
                onClick={addCoauthor}
                style={{
                  maxWidth: "90%",
                  borderRadius: "6px",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "6px 0px",
                }}
              >
                Lägg till medsökande
              </button>
            </span>
            <div>
              <div style={{ marginBottom: "6px" }}>
                <b>Tillagda medsökande: </b>
              </div>
              {coauthor && <span>{renderList}</span>}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
        className="buttons"
      >
        <button
          id="applyinfobutton"
          className="applicationbutton"
          style={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "teal",
            border: "1px solid teal",
            padding: "3px 5px",
            marginRight: "1rem",
          }}
          onClick={Previous}
        >
          Ansökningsinformation
        </button>
        <button
          className="applicationbutton"
          style={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "teal",
            border: "1px solid teal",
            padding: "3px 5px",
            marginRight: "1rem",
          }}
          onClick={Continue}
        >
          Fortsätt
        </button>
        <button
          className="applicationbutton"
          style={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "teal",
            border: "1px solid teal",
            padding: "3px 5px",
            marginRight: "1rem",
          }}
          onClick={postToDb}
        >
          Spara utkast
        </button>
      </div>
    </div>
  );
};

export default PersonalDetails;
