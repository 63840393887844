import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Winners = () => {
  const [winners, setWinners] = useState([]);

  const [year, setYear] = useState(new Date().getFullYear());
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/winners/${year}`
        );
        const posts = await res.json();
        if (Array.isArray(posts)) {
          setWinners(posts);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [year]);

  const handleChange = async (e) => {
    e.preventDefault();
    setYear(e.target.value);
  };

  const addSpace = (text) => {
    let newText = text.slice(0, -3) + " " + text.slice(-3);
    return newText;
  };

  return (
    <>
      <div className="dropdown">
        <select className="select" onChange={handleChange} value={year}>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
        </select>
      </div>
      <div style={{ width: "30vw" }} className="posts" id="winnerposts">
        {!winners ? (
          <></>
        ) : (
          <>
            {winners?.map((winner) => (
              <div className="post" key={winner.id}>
                <div className="content">
                  <h1>
                    {getText(winner.firstname)} {getText(winner.lastname)}{" "}
                  </h1>
                  <h1>{addSpace(getText(winner.prize))} kr</h1>
                  <p>{getText(winner.projectname)}</p>
                  <hr></hr>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default Winners;
