import React, { useState} from "react";
import InfoHover from "../components/InfoHover";

const PrevGrantDetails = ({
  nextStep,
  handleChange,
  prevStep,
  values,
  handleListRemove,
  setState,
  postStateToDb,
  handleListChange,
}) => {
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  const [formData, setFormData] = useState({
    projtitle: "",
    amount: "",
    from: "",
    applicant: "",
    date: "",
  });
  const postToDb = (e) => {
    e.preventDefault();
    postStateToDb();
  };

  const removePrevgrant = (input) => (e) => {
    e.preventDefault();
    let prevgrant = values.prevgrants.filter(function (person) {
      return person !== input;
    });
    handleListRemove(prevgrant, "prevgrants");
    postStateToDb();

    setState();
  };
  const addPrevgrant = (e) => {
    e.preventDefault();
    handleListChange(formData, "prevgrants");
    postStateToDb();
    setFormData({
      projtitle: "",
      amount: "",
      from: "",
      applicant: "",
      date: "",
    });
  };
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const renderList = values?.prevgrants?.map((item, index) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
      }}
      key={index}
    >
      <div>
        {item.date} {item.projtitle} {item.amount}:- {item.from}{" "}
        {item.applicant}
      </div>
      <button onClick={removePrevgrant(item)}>X</button>
    </div>
  ));
  const handleChangeForm = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  return (
    <div id="PrevGrantadd" className="add">
      <div id="PrevGrantmenu" className="menu">
        <div className="content">
          <div className="item">
            <h1 style={{ fontSize: "32px" }}>Tidigare bidrag</h1>
            <div className="cat">
              <input
                type="radio"
                checked={values.prevgrantshedberg === "true"}
                name="prevgrant"
                value="true"
                id="yes"
                onChange={handleChange("prevgrantshedberg")}
              />
              <label htmlFor="yes">
                Tidigare fått bidrag från Hedbergs eller Norlins Stiftelse{" "}
              </label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={values.prevgrantshedberg === "false"}
                name="prevgrant"
                value="false"
                id="no"
                onChange={handleChange("prevgrantshedberg")}
              />
              <label htmlFor="no">
                Ej Tidigare fått bidrag från Hedbergs eller Norlins Stiftelse{" "}
              </label>
            </div>
          </div>
          <div
            style={{
              visibility:
                values.prevgrantshedberg === "true" ? "visible" : "hidden",
            }}
          >
            <div className="item">
              <h1 style={{ fontSize: "32px" }}>Tidigare redovisat bidrag</h1>
              <div className="cat">
                <input
                  type="radio"
                  checked={values.prevgrantshedbergshown == "true"}
                  name="prevgrantshown"
                  value="true"
                  id="yes2"
                  onChange={handleChange("prevgrantshedbergshown")}
                />
                <label htmlFor="yes2">
                  Redan redovisat tidigare bidrag från Hedbergs eller Norlins
                  Stiftelse
                </label>
              </div>
              <div className="cat">
                <input
                  type="radio"
                  checked={values.prevgrantshedbergshown == "false"}
                  name="prevgrantshown"
                  value="false"
                  id="no2"
                  onChange={handleChange("prevgrantshedbergshown")}
                />
                <label htmlFor="no2">
                  Har inte redovisat tidigare bidrag från Hedbergs eller Norlins
                  Stiftelse
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              visibility:
                values.prevgrantshedbergshown == "false" ? "visible" : "hidden",
            }}
          >
            <div className="label">
              <label>
                <h2 style={{ fontSize: "32px" }}>Redovisa tidigare bidrag:</h2>
              </label>
            </div>
            <p className="prevGrantText">
              Redovisa nedan kortfattat (max 2000 tecken) vad tidigare anslag
              använts till samt forskningsprojektets resultat. Om forskning
              stött av Stiftelsen resulterat i en publikation, ange då om
              Stiftelsen omnämnts i acknowledgement eller motsvarande. Förklara
              annars varför det inte varit fallet.
              <br />
              <br />{" "}
            </p>
            {/* <p
              className="prevGramtText"
              style={{
                fontSize: "13px",
                marginBottom: "15px",
              }}
            ></p> */}
            <div className="editorContainer">
              <textarea
                style={{}}
                type="text"
                placeholder="Redovisa tidigare bidrag från Hedbergs stiftelse eller Norlins stiftelse"
                value={values.textprevgranthedberg}
                onChange={handleChange("textprevgranthedberg")}
                maxLength={2000}
              />
            </div>
          </div>
        </div>
        <div className="content" style={{ flex: "3", marginRight: "2px" }}>
          <div className="menu">
            <div>
              <div className="item">
                <div className="label">
                  <h1>Anslag från andra källor:</h1>
                  <InfoHover
                    origin={
                      "Redovisa tidigare anslag, vilka som beviljat anslaget, hur mycket det var på och vem som var huvudsökande."
                    }
                  ></InfoHover>
                </div>
                <span>
                  <i>
                    Projektanslag till detta och närliggande projekt (inklusive
                    ALF-medel)
                  </i>
                </span>
                <form style={{ display: "flex" }}>
                  <div style={{ marginRight: "5px" }}>
                    <div className="label">
                      <label>Projekttitel:</label>
                    </div>
                    <input
                      className="inputPrevGrant"
                      type="text"
                      placeholder="Titel maxlängd 100 tecken"
                      value={formData.projtitle}
                      onChange={handleChangeForm("projtitle")}
                      style={{ marginBottom: "7px" }}
                      maxLength={100}
                    />
                    <div className="label">
                      <label>Belopp:</label>
                    </div>
                    <input
                      className="inputPrevGrant"
                      type="text"
                      placeholder="Belopp"
                      value={formData.amount}
                      onChange={handleChangeForm("amount")}
                      style={{ marginBottom: "7px" }}
                    />
                    <div className="label">
                      <label>Anslagsgivare:</label>
                    </div>
                    <input
                      className="inputPrevGrant"
                      type="text"
                      placeholder="Anslagsgivare"
                      value={formData.from}
                      onChange={handleChangeForm("from")}
                      style={{ marginBottom: "7px" }}
                    />
                  </div>
                  <div style={{ marginRight: "5px" }}>
                    <div className="label">
                      <label>Huvudsökande:</label>
                    </div>
                    <input
                      className="inputPrevGrant"
                      type="text"
                      placeholder="Huvudsökande"
                      value={formData.applicant}
                      onChange={handleChangeForm("applicant")}
                      style={{ marginBottom: "7px" }}
                      required
                    />
                    <div className="label">
                      <label>Beslutsdatum för anslaget:</label>
                    </div>
                    <input
                      className="inputPrevGrant"
                      type="date"
                      placeholder="yyyy-mm-dd"
                      value={formData.date}
                      onChange={handleChangeForm("date")}
                      style={{ marginBottom: "7px", padding: "9px" }}
                    />
                    <div style={{ marginTop: "20px" }} className="buttons">
                      <button
                        type="submit"
                        style={{
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "teal",
                          border: "1px solid teal",
                          padding: "3px 5px",
                          marginRight: "1rem",
                          borderRadius: "6px",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                        onClick={addPrevgrant}
                      >
                        Lägg till tidigare bidrag
                      </button>
                    </div>
                  </div>
                </form>
                <div>
                  <b>Tillagda bidrag: </b>
                  <span>{renderList}</span>
                </div>{" "}
              </div>
            </div>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="buttons"
            >
              <button
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "teal",
                  border: "1px solid teal",
                  padding: "3px 5px",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                  borderRadius: "6px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                onClick={Previous}
              >
                Backa
              </button>
              <button
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "teal",
                  border: "1px solid teal",
                  padding: "3px 5px",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                  borderRadius: "6px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                onClick={Continue}
              >
                Fortsätt
              </button>
              <button
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "teal",
                  border: "1px solid teal",
                  padding: "3px 5px",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                  borderRadius: "6px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                onClick={postToDb}
              >
                Spara utkast
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrevGrantDetails;
