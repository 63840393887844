import React from "react";
import Logo from "../img/logo2.webp";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <img src={Logo} alt="" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <span>
          <b>Producerad av Hedbergsstiftelse</b>.
        </span>
        <div className="footerText">
          <span>
            <Link className="link" to="/gdpr">
              Information om behandling av personuppgifter.
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
