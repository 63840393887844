import React from "react";
import {
  Document,
  Text,
  Page,
  StyleSheet,
  Font,
  View,
} from "@react-pdf/renderer";
let source =
  "https://fonts.gstatic.com/s/ebgaramond/v14/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUAw.ttf";

Font.register({
  family: "Garamond",
  src: source,
});

// Use this font to show alpha
 Font.register({
   family: "Roboto",
   fonts: [
     { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300 },
     { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", fontWeight: 400 },
     { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf", fontWeight: 500 },
     { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 600 },
   ],
 })

const PdfDocument = ({ values }) => {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontFamily: "Roboto",
    },
    title: {
      fontSize: 16,
      textAlign: "center",
      fontFamily: "Roboto",
    },
    author: {
      fontSize: 12,
      fontFamily: "Roboto",
    },
    headerText: {
      fontSize: 12,
      textAlign: "center",
      color: "#525659",
      fontFamily: "Roboto",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    footerText: {
      position: "absolute",
      fontSize: 12,
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
      marginBottom: "8px",
    },
    breakLineGrey: {
      borderBottom: "2px",
      marginBottom: "8px",
      marginTop: "8px",
      borderColor: "#525659",
    },
    breakLineBlack: {
      borderBottom: "1px",
      marginBottom: "2px",
      marginTop: "25px",
      borderColor: "black",
    },
    breakLineWhite: {
      borderBottom: "2px",
      marginBottom: "8px",
      marginTop: "8px",
      borderColor: "white",
    },
    textSignature: {
      fontSize: 15,
      marginBottom: "3px",
      marginTop: "3px",
      fontFamily: "Roboto",
      textAlign: "center",
    },
    projectTitle: {
      fontSize: "18px",
      fontFamily: "Roboto",
      textAlign: "center",
    },
    bold: {
      fontFamily: "Roboto",
    },
    viewstyle: {
      flex: 1,
      width: 500,
      height: 80,
    },
    text: {
      fontSize: 12,
      textAlign: "center",
    },
    viewcontainer: {
      flex: 1,
      width: 600,
      height: 90,
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: 20,
      flexDirection: "row",
    },
  });
  return (
    <Document title="Ansökan Hedberg">
      <Page wrap={true} size={"A4"} style={styles.body}>
        <Text style={styles.headerText}>
          Stiftelsen Apotekare Hedbergs & Bertil och Ebon Norlins Stiftelse för
          Medicinsk Forskning
        </Text>
        <Text style={styles.breakLineGrey}></Text>
        <Text style={styles.text}>Titel: </Text>
        <Text style={styles.title}>{values.title}</Text>
        <Text style={styles.breakLineWhite}></Text>
        <Text style={styles.text}>Huvudsökande: </Text>
        <Text style={styles.text}>
          {values.jobtitle} {values.name}
        </Text>
        <Text style={styles.breakLineWhite}></Text>
        <Text style={[styles.text, {}]}>Prefekt:</Text>
        <Text style={[styles.text, {}]}>{values.prefect}</Text>
        <Text style={styles.breakLineWhite}></Text>

        <Text style={styles.text}>Medsökande: </Text>
        {values.coauthor?.map((item, i) => (
          <Text key={i} style={styles.text}>
            {item.title}, {item.name}
          </Text>
        ))}
        <View style={styles.viewcontainer}>
          <View
            style={[styles.viewstyle, { marginLeft: 60, textAlign: "left" }]}
          >
            <Text style={[styles.text, { textAlign: "left" }]}>
              Kontaktuppgifter:{" "}
            </Text>
            <Text style={[styles.text, { textAlign: "left" }]}>
              Email: {values.email}
            </Text>
            <Text style={[styles.text, { textAlign: "left" }]}>
              Telefon: {values.phone}
            </Text>
          </View>
          <View style={[styles.viewstyle, { marginRight: 20, marginLeft: 50 }]}>
            <Text style={[styles.text, { textAlign: "left" }]}>Adress:</Text>
            <Text style={[styles.text, { textAlign: "left" }]}>
              {values.institution}
            </Text>
            <Text style={[styles.text, { textAlign: "left" }]}>
              {values.university}
            </Text>
            <Text style={[styles.text, { textAlign: "left" }]}>
              {values.address}
            </Text>
            <Text style={[styles.text, { textAlign: "left" }]}>
              {values.city}
            </Text>
          </View>
        </View>

        <Text style={styles.breakLineWhite}></Text>
        <Text style={[styles.title]}>Hypotes:</Text>
        <Text style={styles.breakLineWhite}></Text>

        <Text style={[styles.text]}>{values.hypothesis}</Text>
        <Text style={styles.breakLineWhite}></Text>
        <Text style={[styles.title]}>Projektplan:</Text>
        <Text style={styles.breakLineWhite}></Text>
        <Text style={[styles.text, { textAlign: "left" }]}>{values.desc}</Text>
        <Text style={styles.breakLineWhite}></Text>
        {values.prevgrantshedberg == "true" && (
          <>
            {values.prevgrantshedbergshown == "true" ? (
              <>
                <Text style={[styles.title]}>
                  Redovisning av tidigare anslag från Hedbergs eller Norlins:
                </Text>
                <Text style={styles.breakLineWhite}></Text>
                <Text style={[styles.text, { textAlign: "center" }]}>
                  Har redovisat tidigare
                </Text>
              </>
            ) : (
              <>
                <Text style={[styles.title]}>
                  Redovisning av tidigare anslag från Hedbergs eller Norlins:
                </Text>
                <Text style={styles.breakLineWhite}></Text>
                <Text style={[styles.text, { textAlign: "left" }]}>
                  {values.textprevgranthedberg}
                </Text>
              </>
            )}
          </>
        )}

        <Text style={styles.breakLineWhite}></Text>
        <Text style={[styles.title]}>Tidigare anslag från andra källor:</Text>
        <Text style={styles.breakLineWhite}></Text>
        {values.prevgrants &&
          values.prevgrants?.map((item, i) => (
            <>
              <View key={i}>
                <Text key={item} style={[styles.text, { textAlign: "left" }]}>
                  {item.projtitle}
                </Text>
                <Text
                key={item+i}
                  style={[
                    styles.text,
                    { marginBottom: "5px", textAlign: "left" },
                  ]}
                >
                  {item.date.substring(0, 4)} {item.amount}kr, {item.from},{" "}
                  {item.applicant}
                </Text>
              </View>
            </>
          ))}
        <Text style={styles.breakLineWhite}></Text>
        <Text style={styles.breakLineWhite}></Text>

        <Text style={styles.title}>Underskrifter</Text>
        <Text style={styles.breakLineWhite}></Text>

        <Text style={styles.text}>
          Härmed intygas att samtliga uppgifter som lämnats är riktiga.
          Undertecknande godkänner även att ansökan, inklusive de
          personuppgifter denna innehåller, sparas under de förutsättningar som
          anges på stiftelsens hemsida
        </Text>
        <View wrap={false} style={[styles.viewcontainer, { width: "100%" }]}>
          <View wrap={false} style={[styles.viewstyle, { marginRight: 20 }]}>
            <Text style={styles.breakLineBlack}></Text>
            <Text style={styles.textSignature}>Signatur, huvudsökande</Text>
            <Text style={styles.breakLineBlack}></Text>
            <Text style={styles.text}>Datum, Ort</Text>
          </View>
          <View wrap={false} style={[styles.viewstyle, { marginLeft: 20 }]}>
            <Text style={styles.breakLineBlack}></Text>
            <Text style={styles.textSignature}>Signatur, prefekt</Text>
            <Text style={styles.breakLineBlack}></Text>
            <Text style={styles.text}>Datum, Ort</Text>
          </View>
        </View>
        <Text style={styles.footerText}>
          {" "}
          Underskriven blankett skickas inom 7 dagar scannad till: je@eslaw.se
          (Ordförande Jonas Eilert)
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PdfDocument;
