import React, { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";

const Profile = () => {
  const { currentUser, setState, updateUsers } = useContext(AuthContext);
  const [inputs, setInputs] = useState(currentUser);
  const [err, setError] = useState(null);
  const [ok, setOk] = useState(null);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const updateUser = async (e) => {
    e.preventDefault();
    try {
      let values = {
        firstname: inputs.firstname,
        lastname: inputs.lastname,
        password: inputs.password,
        newPassword: inputs.newPassword,
        confirmNewPassword: inputs.confirmNewPassword,
      };
      let user = await updateUsers(values);
      if (user.firstname) {
        setOk("Konto uppdaterat!");
        setError(null);
        setState();
      } else {
        setOk(null);
        setError(user);
        setState();
      }
    } catch (error) {
      setOk(null);
      setError(error);
    }
  };

  return (
    <div className="auth">
      <form style={{ gap: "10px" }}>
        <label className="label">Email:</label>
        <input disabled type="text" defaultValue={currentUser.email}></input>
        <label className="label">Förnamn:</label>
        <input
          onChange={handleChange}
          name="firstname"
          type="text"
          defaultValue={currentUser.firstname}
        ></input>
        <label className="label">Efternamn:</label>
        <input
          onChange={handleChange}
          name="lastname"
          type="text"
          defaultValue={currentUser.lastname}
        ></input>

        <label className="label">Nuvarande lösenord:</label>
        <input onChange={handleChange} name="password" type="password"></input>

        <label className="label">Nytt lösenord:</label>
        <input
          onChange={handleChange}
          name="newPassword"
          type="password"
        ></input>

        <label className="label">Bekräfta nytt lösenord:</label>
        <input
          onChange={handleChange}
          name="confirmNewPassword"
          type="password"
        ></input>

        <button onClick={updateUser} type="submit">
          Updatera!
        </button>
        {err && (
          <p style={{ color: "red", borderStyle: "none", fontSize: "16px" }}>
            {err}
          </p>
        )}
        {ok && <p style={{ backgroundColor: "green", color: "black" }}>{ok}</p>}
      </form>
    </div>
  );
};

export default Profile;
