import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApplyContext } from "../context/applyContext";
import InfoHover from "../components/InfoHover";
import { put } from "@vercel/blob";
import { SpinnerCircular } from "spinners-react";

const ProjectDetails = ({
  nextStep,
  handleChange,
  prevStep,
  values,
  handleChangeFile,
  postStateToDb,
  handleListChange,
  setState,
  handleListRemove,
}) => {
  const [cv, setCv] = useState(null);
  const [researchprog, setResearchprog] = useState(null);
  const [projPlan, setProjPlan] = useState(values?.desc || "");
  const [publications, setPublications] = useState(null);

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  let pdfcvname = values.cv
    ?.substring(56, values.cv.length - 35)
    .split("%20")
    .join(" ")
    .split("%C3%83%C2%B6")
    .join("ö")
    .split("%C3%83%C2%85")
    .join("å")
    .split("a%C3%8C%C2%88")
    .join("ä");

  let pdfresearchname = values.researchprog
    ?.substring(56, values.researchprog.length - 35)
    .split("%20")
    .join(" ")
    .split("%C3%83%C2%B6")
    .join("ö")
    .split("%C3%83%C2%85")
    .join("å")
    .split("a%C3%8C%C2%88")
    .join("ä");

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };
  const postToDb = (e) => {
    e.preventDefault();
    postStateToDb();
  };
  const downloadFile = async (type) => {
    try {
      const { id } = values.id;
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/posts/download/${type}/${values.id}`,
        { responseType: "blob" }
      );

      const blob = new Blob([res.data], { type: res.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      if (type === "cv") {
        link.download = `${values.cv}`;
      } else {
        link.download = `${values.researchprog}`;
      }

      // link.download = res.headers["content-disposition"].split("filename=")[1];
      link.click();
      window.open(link.href, "_blank");
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const uploadresearchProg = async (researchprog) => {
    try {
      const formData = new FormData();
      formData.append("file", researchprog);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/upload`, {
        method: "POST",
        body: formData,
      });
      const res = await response.json();

      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadcv = async (cv) => {
    try {
      const formData = new FormData();

      formData.append("file", cv);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/upload`, {
        method: "POST",
        body: formData,
      });
      const res = await response.json();
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
    }
  };
  const removePublication = (input) => (e) => {
    e.preventDefault();
    let publication = values.publications.filter(function (publication) {
      return publication !== input;
    });

    handleListRemove(publication, "publications");
    postStateToDb();

    setState();
  };
  const removeCv = (input) => (e) => {
    e.preventDefault();
    values.cv = "";
    postStateToDb();
    setState();
  };
  const removeResearchprog = (input) => (e) => {
    e.preventDefault();
    values.researchprog = "";
    postStateToDb();
    setState();
  };
  const uploadpublications = async (publications) => {
    try {
      const formData = new FormData();

      formData.append("file", publications);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/upload`, {
        method: "POST",
        body: formData,
      });
      const res = await response.json();

      return res;
    } catch (err) {
      console.log(err);
    }
  };
  const handleFile = (input) => async (e) => {
    if (input === "cv") {
      let imgUrl = "";
      setCv(e.target.files[0]);
      imgUrl = await uploadcv(e.target.files[0]);
      const res = await handleChangeFile(input, imgUrl);
      values.cv = imgUrl;
      setCv(imgUrl);
      postToDb(e);
    } else if (input === "publications") {
      if (values.publications.length === 4) {
        alert("Du har redan laddat upp 4st");
        return;
      } else {
        let imgUrl = "";
        imgUrl = await uploadpublications(e.target.files[0]);
        const res = await handleListChange(imgUrl, input);
        setPublications(imgUrl);
        postToDb(e);
      }
    } else {
      let imgUrl = "";
      setResearchprog(e.target.files[0]);
      imgUrl = await uploadresearchProg(e.target.files[0]);
      const res = await handleChangeFile(input, imgUrl);
      values.researchprog = imgUrl;
      setResearchprog(imgUrl);
      postToDb(e);
    }
  };

  return (
    <div className="add">
      <div className="content">
        {" "}
        <h2
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "32px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Information om forskningsprojektet
        </h2>
        <div className="label">
          <label>
            <h3>Titel:</h3>
            <p
              id="spanProjectDetails"
              style={{ fontSize: "14px", marginTop: "5px" }}
            >
              Ange forskningsprojektets titel
            </p>{" "}
          </label>
        </div>
        <input
          style={{ fontSize: "14px" }}
          type="text"
          placeholder="Titel"
          value={values?.title}
          onChange={handleChange("title")}
        />
        <div className="label">
          <label>
            <h3>Hypotes:</h3>
            <p
              id="spanProjectDetails"
              style={{ fontSize: "14px", marginTop: "5px" }}
            >
              Ange kortfattat forskningsprojektets hypotes på svenska,{" "}
              <i>max 200 tecken</i>.
            </p>
          </label>
          <InfoHover
            origin={
              "Stiftelsen prioriterar ansökningar med en tydlig och välformulerad hypotes. Hypotesgenererande studier där stora mängder data samlas in för att leta möjliga samband ges låg prioritet."
            }
          ></InfoHover>
        </div>
        <div style={{ height: "80px" }} className="editorContainer">
          <textarea
            style={{}}
            type="text"
            placeholder="Hypotes"
            value={values.hypothesis}
            onChange={handleChange("hypothesis")}
            maxLength={200}
          />
        </div>
        <div className="label">
          <label>
            {" "}
            <h3>Kortfattad projektplan:</h3>
            <p
              id="spanProjectDetails"
              style={{ fontSize: "14px", marginTop: "5px" }}
            >
              Sammanfatta ditt forskningsprojekt på svenska,
              <i> max 2500 tecken.</i>
              <br />
              <b>Exempel på lämpliga underrubriker:</b> bakgrund, tidigare
              resultat, metod, samt betydelse.
            </p>
          </label>
          <InfoHover
            origin={[
              "Det ska framgå av ”bakgrund” vad hypotesen (föregående box) baseras på och det är av stor vikt att det av beskrivningen under ”metod” verkligen framgår att studien förmår att testa hypotesen. ",
              <br />,
              "Likaså ska det av underrubriken ”betydelse” framgå att ett verifierande eller avfärdande av hypotesen kommer leda till väsentlig skillnad.",
            ]}
          ></InfoHover>
        </div>
        <div className="editorContainer">
          <textarea
            style={{}}
            type="text"
            placeholder="Projektplan"
            value={values.desc}
            onChange={handleChange("desc")}
            maxLength={2500}
          />
        </div>
      </div>
      <div className="menu">
        <div className="item">
          <div className="label">
            <h1>Huvudsökandes CV</h1>
          </div>
          <span className="spanProjectDetails">
            Bifoga huvudsökandes CV nedan. Filen ska vara i PDF-format och kan
            vara på engelska.
          </span>

          <input
            style={{ display: "none" }}
            type="file"
            id="cv"
            accept="application/pdf"
            name=""
            onChange={handleFile("cv")}
            //onChange={handleChange('cv')}
          />
          <div style={{ marginRight: "5vw" }} className="buttons">
            {cv?.name ? (
              <SpinnerCircular
                size={35}
                thickness={180}
                speed={100}
                color="rgba(57, 172, 131, 1)"
                secondaryColor="rgba(0, 0, 0, 0.44)"
              />
            ) : (
              <label
                className="file"
                htmlFor="cv"
                style={{ marginBottom: "10px" }}
              >
                Välj fil att ladda upp:
              </label>
            )}
            {/* <button>Save as a draft</button> */}
            {/* <button className="applicationbutton" onClick={handleFile("cv")}>
              Publicera fil
            </button> */}
          </div>
          <label className="prevUploadText">
            <b>Tidigare uppladdade filer: </b>
          </label>

          {values.cv && (
            <div className="buttons">
              <button
                style={{ display: "flex", justifyContent: "space-between" }}
                className="applicationbutton"
              >
                <a href={values.cv} target="_blank">
                  {pdfcvname && pdfcvname}
                </a>
                <button
                  onClick={removeCv()}
                  style={{
                    // backgroundColor: "#E76161",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                >
                  X
                </button>
              </button>
            </div>
          )}
        </div>

        <div className="item">
          <h1>Projektets natur:</h1>
          <div className="cat">
            <input
              type="radio"
              checked={values.cat === "group"}
              name="cat"
              value="group"
              id="group"
              onChange={handleChange("cat")}
            />
            <label htmlFor="group">
              Ansökan avser en specifik del inom ett större projekt
            </label>
          </div>
          <div className="cat" style={{ marginBottom: "10px" }}>
            <input
              type="radio"
              checked={values.cat === "independent"}
              name="cat"
              value="independent"
              id="independent"
              onChange={handleChange("cat")}
            />
            <label htmlFor="independent">
              Ansökan avser ett fristående projekt
            </label>
          </div>
          {values.cat === "independent" ? (
            <>
              <div className="label">
                <h4>Forskningsprogram</h4>
                {/* <InfoHover
                  origin={
                    "Bifoga nödvändiga bilagor. Filerna ska vara i PDF-format och max 4 sidor."
                  }
                ></InfoHover> */}
              </div>
              <span className="spanProjectDetails">
                Forskningsprojekt bifogas nedan. Filen ska vara i PDF-format och{" "}
                <b>max 4 sidor.</b>
              </span>{" "}
              <div className="buttons" style={{ marginRight: "5vw" }}>
                {researchprog?.name ? (
                  <SpinnerCircular
                    size={35}
                    thickness={180}
                    speed={100}
                    color="rgba(57, 172, 131, 1)"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                ) : (
                  <label
                    className="file"
                    htmlFor="researchprog"
                    style={{ marginBottom: "10px" }}
                  >
                    Välj fil att ladda upp
                  </label>
                )}

                {/* <button>Save as a draft</button> */}

                {/* <button
                  className="applicationbutton"
                  onClick={handleFile("researchprog")}
                >
                  Publicera fil
                </button> */}
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                id="researchprog"
                name=""
                accept="application/pdf"
                onChange={handleFile("researchprog")}
                //onChange={handleChange('cv')}
              />
              <span className="spanProjectDetails">
                <label className="prevUploadText">
                  <b>Tidigare uppladdade filer: </b>
                </label>
              </span>
              {values.researchprog && (
                <div className="buttons">
                  <button
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="applicationbutton"
                  >
                    {" "}
                    <a href={values.researchprog} target="_blank">
                      {pdfresearchname && pdfresearchname}
                    </a>{" "}
                    <button
                      onClick={removeResearchprog()}
                      style={{
                        // backgroundColor: "#E76161",
                        backgroundColor: "white",
                        borderRadius: "4px",
                        marginLeft: "5px",
                      }}
                    >
                      X
                    </button>
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="label">
                <h4>Nyligen inskickad ansökan </h4>
                {/* <InfoHover
                  origin={
                    "Bifoga nödvändiga bilagor. Filerna ska vara i PDF-format"
                  }
                ></InfoHover> */}
              </div>
              <span>
                <i>tex. till VR, cancerfonden eller lokal ALF-kommité</i>
              </span>
              <br />{" "}
              <div className="buttons" style={{ marginRight: "5vw" }}>
                {researchprog?.name ? (
                  <SpinnerCircular
                    size={35}
                    thickness={180}
                    speed={100}
                    color="rgba(57, 172, 131, 1)"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                ) : (
                  <label
                    className="file"
                    htmlFor="researchprog"
                    style={{ marginBottom: "10px" }}
                  >
                    Välj fil att ladda upp
                  </label>
                )}

                {/* <span style={{ marginTop: "10px" }}>
                  <b>{researchprog?.name}</b>
                </span> */}

                {/* <button>Save as a draft</button> */}

                {/* <button
                  className="applicationbutton"
                  onClick={handleFile("researchprog")}
                >
                  Publicera fil
                </button> */}
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                accept="application/pdf"
                id="researchprog"
                name=""
                onChange={handleFile("researchprog")}
                //onChange={handleChange('cv')}
              />
              <span className="spanProjectDetails">
                <label className="prevUploadText">
                  <b>Tidigare uppladdade filer: </b>
                </label>
              </span>
              <span>
                {values.researchprog && (
                  <div className="buttons">
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="applicationbutton"
                    >
                      {" "}
                      <a href={values.researchprog} target="_blank">
                        {pdfresearchname && pdfresearchname}
                      </a>
                      <button
                        onClick={removeResearchprog()}
                        style={{
                          //
                          // backgroundColor: "#E76161",
                          backgroundColor: "white",
                          borderRadius: "4px",
                          marginLeft: "5px",
                        }}
                      >
                        X
                      </button>
                    </button>
                  </div>
                )}
              </span>
            </>
          )}
        </div>
        <div className="item">
          <div className="label">
            <h1>Ladda upp publikationer</h1>
          </div>
          <span className="spanProjectDetails">
            Bifoga publikationer nedan, (PDF-format, max 4 filer). Filerna kan
            vara på engelska.
          </span>
          <div style={{ marginRight: "5vw" }} className="buttons">
            {publications?.name ? (
              <SpinnerCircular
                size={35}
                thickness={180}
                speed={100}
                color="rgba(57, 172, 131, 1)"
                secondaryColor="rgba(0, 0, 0, 0.44)"
              />
            ) : (
              <label
                className="file"
                htmlFor="publications"
                style={{ marginBottom: "10px" }}
              >
                Välj fil att ladda upp
              </label>
            )}
            {/* <button>Save as a draft</button> */}
            {/* <button
              className="applicationbutton"
              onClick={handleFile("publications")}
            >
              Publicera fil
            </button> */}
          </div>
          <input
            style={{ display: "none" }}
            type="file"
            id="publications"
            accept="application/pdf"
            name=""
            onChange={handleFile("publications")}
            //onChange={handleChange('cv')}
          />
          <span className="spanProjectDetails">
            <b>Tidigare uppladdade filer: </b>
          </span>
          <div
            style={{
              display: "grid",
            }}
            className="buttons"
          >
            {Array.isArray(values.publications) &&
              values.publications.map((item, i) => (
                <button
                  key={i}
                  className="applicationbutton"
                  style={{
                    marginTop: "2px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <a style={{ textDecoration: "none" }} href={item}>
                    {item
                      ?.substring(56, item.length - 35)
                      .split("%20")
                      .join(" ")
                      .split("%C3%83%C2%B6")
                      .join("ö")
                      .split("%C3%83%C2%85")
                      .join("å")
                      .split("a%C3%8C%C2%88")
                      .join("ä")}
                  </a>
                  <button
                    onClick={removePublication(item)}
                    style={{
                      // backgroundColor: "#E76161",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      marginLeft: "5px",
                    }}
                  >
                    X
                  </button>
                </button>
              ))}
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="buttons"
        >
          <button
            className="applicationbutton"
            style={{
              cursor: "pointer",
              color: "white",
              backgroundColor: "teal",
              border: "1px solid teal",
              padding: "3px 5px",
              marginRight: "1rem",
            }}
            onClick={Previous}
          >
            <b>Backa</b>
          </button>
          <button
            className="applicationbutton"
            style={{
              cursor: "pointer",
              color: "white",
              backgroundColor: "teal",
              border: "1px solid teal",
              padding: "3px 5px",
              marginRight: "1rem",
            }}
            onClick={Continue}
          >
            <b>Fortsätt</b>
          </button>
          <button
            className="applicationbutton"
            style={{
              cursor: "pointer",
              color: "white",
              backgroundColor: "teal",
              border: "1px solid teal",
              padding: "3px 5px",
              marginRight: "1rem",
            }}
            onClick={postToDb}
          >
            <b>Spara utkast</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
