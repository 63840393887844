import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";

const Register = () => {
  const [inputs, setInputs] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(inputs.email)) {
      setError("Inte giltig email");
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputs),
        }
      );
      const res = await response.json();
      if (res.message) {
        setError(res.message);
      } else {
        navigate("/login");
      }

      // await axios.post('/auth/register', inputs);
      //
    }
  };

  return (
    <>
      <div className="auth">
        <form>
          <h1>Registrera</h1>
          <input
            required
            type="text"
            placeholder="Förnamn"
            name="firstname"
            autoComplete="given-name"
            onChange={handleChange}
          />
          <input
            required
            type="text"
            placeholder="Efternamn"
            name="lastname"
            autoComplete="family-name"
            onChange={handleChange}
          />
          <input
            required
            type="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            onChange={handleChange}
          />
          <input
            required
            type="password"
            placeholder="Lösenord"
            name="password"
            onChange={handleChange}
            minLength="2"
          />
          <input
            required
            type="password"
            placeholder="Bekräfta lösenord"
            name="confirmPassword"
            onChange={handleChange}
            minLength="2"
          />
          <button onClick={handleSubmit}>Registrera</button>
          {err && (
            <p style={{ color: "red", borderStyle: "none", fontSize: "16px" }}>
              {err}
            </p>
          )}
          <span>
            Har du ett konto? <Link to="/login">Logga in</Link>
          </span>
        </form>
      </div>
    </>
  );
};

export default Register;
