//imports
import React from "react";
import { Link } from "react-router-dom";
const ApplyInfo = ({ nextStep, prevStep }) => {
  //variables

  //functioner

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    //html

    <>
      <div className="add">
        <div className="content">
          {" "}
          <h1
            style={{
              fontSize: "2em",
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            Ansökningsinformation
          </h1>
          <div className="containerTextBorder">
            <h3>Ansökningsprocessen & allmän information</h3>
            För att ansöka om anslag måste du skapa ett konto, vilket du kan
            göra{" "}
            <Link className="link" to="/register">
              här
            </Link>{" "}
            eller logga in om du redan har ett konto. Du behöver fylla i
            kontaktuppgifter till aktuellt Universitet, eventuella medsökande
            och bifoga relevanta filer. Ansökan ska skrivas på svenska (dock kan
            bifogade filer och CV vara på engelska). Dina personuppgifter kommer
            att behandlas i enlighet med GDPR. <br /> <br />
            Du kan redigera detaljerna i din ansökan fram till sista
            ansökningsdagen, därefter är den inte längre vara redigerbar. Om
            slutgiltig ansökan har skickats in innan sista ansökningsdag och
            eventuella ändringar skulle behöva göras kan support kontaktas.
            <br />
            När du har fyllt i samtliga uppgifter och bifogat relevanta filer
            görs ansökan om till en PDF som ska skrivas ut för undertecknande av
            huvudsökande och prefekt eller motsvarande (obs underskrift av
            prefekt är en förutsättning för utdelning). Dokumentet skickas till
            ordförade via e-post till je@eslaw.se. <br />
            <br />
            Ansökningsperioden slutar den <strong>15 November 2023</strong>.
            Därefter kommer ansökningarna att granskas av stiftelsens styrelse.
            Anslagen kommer att delas ut under mars 2024 och utbetalas via
            mottagarens institution.
            <br />
            <br />
            <br />
            <h3>Vid frågor</h3>
            För frågor avseende tekniska problem som t.ex. registrering,
            lösenord eller annat rörande ansökningsförfarandet, kontakta support
            via support@hedbergsstiftelse.se.
            <br />
            För övriga frågor rörande stiftelserna eller stipendium kontakta
            styrelsens ordförade Jonas Eilert via je@eslaw.se <br />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="buttons"
          >
            <button
              style={{
                cursor: "pointer",
                color: "white",
                backgroundColor: "teal",
                border: "1px solid teal",
                padding: "7px 9px",
                fontWeight: "bold",
                fontSize: "15px",
                borderRadius: "6px",
              }}
              onClick={Continue}
            >
              Fortsätt
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyInfo;
