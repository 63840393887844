import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../context/authContext";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const TableBody = ({ tableData, columns, admins, updateState }) => {
  const { currentUser } = useContext(AuthContext);
  const [currentData, setCurrentData] = useState(tableData);
  const items = useMemo(() => currentData?.map(({ id }) => id), [currentData]);
  const scores = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    setCurrentData(tableData);
  }, [tableData]);
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setCurrentData((currentData) => {
        const current = currentData.find((e) => e.id == active.id);
        const oldIndex = currentData.indexOf(current);
        const newData = currentData.find((e) => e.id == over.id);
        const newIndex = currentData.indexOf(newData);
        const array = arrayMove(currentData, oldIndex, newIndex);

        array.map((e) => {
          if (
            e.finalgrades.filter((e) => e.user === currentUser?.id).length > 0
          ) {
            e.finalgrades.forEach((element) => {
              if (element.user == currentUser.id) {
                element.grade = array.indexOf(e) + 1;
              }
            });
          } else {
            e.finalgrades.push({
              user: currentUser.id,
              grade: array.indexOf(e) + 1,
            });
          }
          e.myfinalgrade = array.indexOf(e) + 1;
        });
        updateState(array);
        setCurrentData(array);
        postStateToDb(array);
        return array;
      });
    }
  }

  const postStateToDb = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/posts/many`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const res = await response.json();
  };
  const Dragablerow = (post) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: post.value.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    const handleOnChangeGrade = (id) => (e) => {
      let thisData = currentData.filter((e) => e.id === id);
      let theOtherData = currentData.filter((e) => e.id !== id);
      if (
        thisData[0].grades?.filter((e) => e.user === currentUser?.id).length > 0
      ) {
        thisData[0].grades?.forEach((element) => {
          if (element.user == currentUser.id) {
            element.grade = e.target.value;
          }
        });
      } else {
        thisData[0].grades = {
          user: currentUser.id,
          grade: e.target.value,
        };
      }

      thisData[0].mygrade = e.target.value;
      theOtherData.splice(thisData[0].myfinalgrade - 1, 0, thisData[0]);
      setCurrentData(theOtherData);

      postStateToDb(thisData);
    };
    return (
      <>
        <tr style={style}>
          {columns.map(({ accessor }) => {
            if (accessor === "createdAt") {
              const tData = post.value[accessor]
                ? post.value[accessor]
                : ["——"];
              return <td key={accessor}>{tData?.substring(0, 10)}</td>;
            }
            //else if (accessor === "finalgrades") {
            //   const tData = Object.values(post.value[accessor]);
            //   const mytData = tData.filter((e) => e.user == currentUser.id);
            //   const mygrade = mytData.map((e) => e.grade)[0];
            //   return <td key={accessor}>{mygrade}</td>;
            //}
            else if (accessor === "myfinalgrade") {
              const tData = post.value[accessor] ? post.value[accessor] : "——";
              return (
                <td
                  ref={setNodeRef}
                  {...attributes}
                  {...listeners}
                  key={accessor}
                >
                  {tData}
                </td>
              );
            } else if (accessor === "mygrade") {
              const tData = post.value[accessor] ? post.value[accessor] : "——";
              return (
                <td key={accessor} style={{ padding: "0" }}>
                  <select
                    onChange={handleOnChangeGrade(post.value.id)}
                    style={{
                      marginLeft: "0rem",
                      cursor: "pointer",
                      borderStyle: "none",
                      fontSize: "15px",
                    }}
                    defaultValue={tData}
                  >
                    {/* TODO FIX OPTION SHOWN */}
                    <option value="" hidden>
                      ——
                    </option>
                    {scores.map((score) => {
                      return (
                        <option value={score} key={score}>
                          {score}
                        </option>
                      );
                    })}
                  </select>
                </td>
              );
            } else {
              const tData = post.value[accessor] ? post.value[accessor] : "——";
              return (
                <td
                  onClick={() =>
                    navigate(`/post/${post.value.id}`, {
                      state: { currentData },
                    })
                  }
                  key={accessor}
                >
                  {tData}
                </td>
              );
            }
          })}
        </tr>
      </>
    );
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <tbody>
          {currentData.map((post) => (
            <Dragablerow key={post.id} value={post} />
          ))}
        </tbody>
      </SortableContext>
    </DndContext>
  );
};

export default TableBody;
