import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { BlobAccessError } from "@vercel/blob";

const TableBody = ({ tableData, columns, admins, updateState }) => {
  const { currentUser } = useContext(AuthContext);
  const [currentData, setCurrentData] = useState(tableData);

  useEffect(() => {
    setCurrentData(tableData);
  }, [tableData]);
  const navigate = useNavigate();

  const postStateToDb = async (data) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
  };

  const handleOnChange = (id) => (e) => {
    let thisData = currentData.filter((e) => e.id === id);
    let theOtherData = currentData.filter((e) => e.id !== id);
    thisData[0].scorer = e.target.value;
    postStateToDb(thisData[0]);
    theOtherData.push(thisData[0]);
    updateState(theOtherData);
  };

  const handleWinnerOnChange = (id) => {
    let thisData = currentData.filter((e) => e.id === id);
    let theOtherData = currentData.filter((e) => e.id !== id);

    if (thisData[0].winner === true) {
      thisData[0].winner = false;
      postStateToDb(thisData[0]);

      theOtherData.push(thisData[0]);
      updateState(theOtherData);
    } else {
      thisData[0].winner = true;
      postStateToDb(thisData[0]);

      theOtherData.push(thisData[0]);
      updateState(theOtherData);
    }
  };

  return (
    <tbody>
      {currentData.map((data) => {
        return (
          <tr key={data.id}>
            {columns.map(({ accessor }) => {
              if (accessor === "scorer") {
                const tData = data[accessor] ? data[accessor] : "——";
                return (
                  <td key={accessor} style={{ padding: "0" }}>
                    <select
                      onChange={handleOnChange(data.id)}
                      style={{
                        marginLeft: "0rem",
                        cursor: "pointer",
                        borderStyle: "none",
                        fontSize: "15px",
                      }}
                      defaultValue={tData}
                    >
                      <option value="" hidden>
                        ——
                      </option>
                      {admins.map((admin) => {
                        return (
                          <>
                            {data["bias"]?.some((v) => v == admin?.firstname) ? (
                              <option disabled value={admin.id} key={admin.id}>
                                {admin?.firstname}
                              </option>
                            ) : (
                              <option value={admin.id} key={admin.id}>
                                {admin?.firstname}
                              </option>
                            )}
                          </>
                        );
                      })}
                    </select>
                  </td>
                );
              } else if (accessor === "bias") {
                const tData = data[accessor] ? data[accessor] : ["——"];
                return (
                  <td key={accessor}>{tData.map((item) => item + " ")}</td>
                );
              } else if (accessor === "winner") {
                return (
                  <td style={{ cursor: "auto" }} key={accessor}>
                    <input
                      style={{
                        marginLeft: "1rem",
                        cursor: "pointer",
                      }}
                      defaultChecked={data[accessor] === true}
                      type="checkbox"
                      onClick={() => handleWinnerOnChange(data.id)}
                    />
                  </td>
                );
              } else if (accessor == "createdAt") {
                const tData = data[accessor] ? data[accessor] : "——";
                return (
                  <td
                    onClick={() =>
                      navigate(`/post/${data.id}`, { state: { currentData } })
                    }
                    key={accessor}
                  >
                    {tData.substring(0, 10)}
                  </td>
                );
              } else if (accessor == "finalist") {
                let tData = data[accessor] ? data[accessor] : "——";
                if (tData == true) {
                  tData = "Ja";
                }
                return (
                  <td
                    onClick={() =>
                      navigate(`/post/${data.id}`, { state: { currentData } })
                    }
                    key={accessor}
                  >
                    {tData}
                  </td>
                );
              } else if (accessor == "avgfinalgrades") {
                // const tData = data[accessor] ? data[accessor] : [{ grade: 0 }];

                // let sum = 0;
                // tData.map((e) => (sum = sum + e.grade));
                // let average = 0;
                // if (tData.length > 0) {
                //   average = sum / tData.length;
                // }
                // return <td key={accessor}>{parseInt(average)}</td>;

                const tData = data[accessor] ? data[accessor].toFixed(2) : null;
                return (
                  <td
                    onClick={() =>
                      navigate(`/post/${data.id}`, { state: { currentData } })
                    }
                    key={accessor}
                  >
                    {tData}
                  </td>
                );
              } else {
                const tData = data[accessor] ? data[accessor] : "——";
                return (
                  <td
                    onClick={() =>
                      navigate(`/post/${data.id}`, { state: { currentData } })
                    }
                    key={accessor}
                  >
                    {tData}
                  </td>
                );
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
