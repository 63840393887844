import React from "react";

const ErrorElement = () => {
  return (
    <div className="add">
      <div className="content" style={{ textAlign: "center" }}>
        Oj något gick fel!
      </div>
    </div>
  );
};

export default ErrorElement;
