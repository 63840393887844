import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let user = await login(inputs);

      if (user?.role) {
        if (user?.role === "admin") {
          navigate("/admin");
        } else if (user?.role === "lawyer") {
          navigate("/lawyer");
        } else if (user?.role === "reader") {
          navigate("/reader");
        } else if (user?.role === "superadmin") {
          navigate("/admin");
        } else if (user?.role === "scorer") {
          navigate("/admin");
        } else {
          navigate("/apply");
        }
      } else {
        setError(user?.message);
      }
    } catch (err) {
      setError(err?.message);
    }
  };
  return (
    <>
      <div className="auth">
        <form>
          <h1>Login</h1>
          <input
            required
            type="email"
            placeholder="Email"
            autoComplete="email"
            name="email"
            onChange={handleChange}
          />
          <input
            required
            type="password"
            placeholder="Lösenord"
            name="password"
            onChange={handleChange}
          />
          <button onClick={handleSubmit}>Login</button>
          {err && (
            <p style={{ color: "red", borderStyle: "none", fontSize: "16px" }}>
              {err}
            </p>
          )}
          <span>
            Har du inget konto? <br />
            <Link to="/register">Registrera dig</Link>
            <br />
            <Link to="/resetpassword">Glömt lösenord?</Link>
          </span>
        </form>
      </div>
    </>
  );
};

export default Login;
