import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const ResetPassword = () => {
  const [err, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [inputs, setInputs] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/resetpassword`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs),
      }
    ).catch((error) => {
      console.log(error);
      return;
    });
    const response = await res?.json();
    console.log(response);
    if (response.status == 400) {
      setError(response.message);
      setSuccess(null);
    } else {
      setSuccess(response);
      setError(null);
    }
  };

  return (
    <>
      <div className="auth">
        <form>
          <h1>Återställ lösenord</h1>
          <input
            required
            type="email"
            placeholder="Email"
            autoComplete="email"
            name="email"
            onChange={handleChange}
          />
          <button onClick={handleSubmit}>Skicka lösenord</button>
          {err && (
            <p style={{ color: "red", borderStyle: "none", fontSize: "16px" }}>
              {err}
            </p>
          )}
          {success && (
            <p
              style={{
                color: "green",
                borderStyle: "none",
                fontSize: "16px",
                backgroundColor: "black",
              }}
            >
              {success}
            </p>
          )}
          <span>
            Har du inget konto? <br />
            <Link to="/register">Registrera dig</Link>
          </span>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
