import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useContext } from "react";

const Submitted = () => {
  // javascript
  const { currentUser } = useContext(AuthContext);
  return (
    // htmlkod
    <div className="add">
      <div className="content">
        {" "}
        <h1
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginLeft: "20px",
            marginRight: "20px",
            textDecoration: "underline",
          }}
        >
          Tack för din ansökan {currentUser?.firstname}!
        </h1>
        <p id="textSubmitted"></p>
        <div className="containerTextBorder">
          <p id="textSubmitted">
            <b> Din ansökan har mottagits och registrerats i vårt system.</b>{" "}
            <br />
            <br />
            <h4>Vad händer nu?</h4>
            <ul>
              <li className="listSubmitted">
                Inom 7 dagar behöver du enligt tidigare instruktioner att skicka
                in en signerad version av ansökan.{" "}
              </li>
              <li className="listSubmitted">
                Ansökan ska signeras av huvudsökande och prefekt (prefekt eller
                motsvarandes signatur är obligatorisk för godkänd ansökan).
              </li>{" "}
              <li className="listSubmitted">
                Om du behöver göra några ändringar i inskickad ansökan, vänligen
                kontakta Stiftelsen Hedbergs webadministratör på
                support@hedbergsstiftelse.se, vänligen ange titel på
                forskningsproject samt namn på huvudsökande vid{" "}
                <span>
                  {" "}
                  <Link
                    className="link"
                    style={{ textDecoration: "underline", fontSize: "16px" }} 
                    to="/contact"
                  >
                    {" "}
                    kontakt.
                  </Link>
                </span>
              </li>
            </ul>
          </p>{" "}
          <br />
          <p></p>
        </div>
      </div>
    </div>
  );
};
export default Submitted;
