import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Logo from "../img/hedberg-top.png";
import { useNavigate } from "react-router-dom";
import Hamburger from "../img/hamburger-menu-line-icon-free-vector.jpg";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    navigate("/");
  };

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="mobilelinks">
          {currentUser ? (
            <span className="mobilelogin" onClick={handleLogout}>
              Logout
            </span>
          ) : (
            <Link className="mobilelogin" to="/login">
              Login
            </Link>
          )}
          {/* <img
            onClick={handleShow}
            style={{ width: "50px" }}
            src={Hamburger}
          ></img>{" "} */}
        </div>
        <div className="links">
          <Link className="link" to="/">
            <h6>Hem</h6>
          </Link>
          {!currentUser && (
            <Link className="link" to="/apply">
              <h6>Ansök</h6>
            </Link>
          )}
          {currentUser?.role === "user" && (
            <Link className="link" to="/apply">
              <h6>Ansök</h6>
            </Link>
          )}
          <Link className="link" to="/winners">
            <h6>Tidigare pristagare</h6>
          </Link>
          <Link className="link" to="/contact">
            <h6>Kontakt</h6>
          </Link>
          {currentUser && currentUser.role === "scorer" && (
            <Link className="link" to="/admin">
              Adminsida
            </Link>
          )}
          {currentUser && currentUser.role === "admin" && (
            <Link className="link" to="/admin">
              Adminsida
            </Link>
          )}
          {currentUser && currentUser.role === "superadmin" && (
            <Link className="link" to="/admin">
              Adminsida
            </Link>
          )}
          {currentUser && currentUser.role === "lawyer" && (
            <Link className="link" to="/lawyer">
              Adminsida
            </Link>
          )}
          {currentUser && currentUser.role === "reader" && (
            <Link
              className="link"
              style={{ textDecoration: "none" }}
              to="/reader"
            >
              <h6>Adminsida</h6>
            </Link>
          )}
          <Link className="link" to="/profile">
            {currentUser?.firstname}
          </Link>
          {currentUser ? (
            <span className="write" onClick={handleLogout}>
              Logout
            </span>
          ) : (
            <Link className="write" to="/login">
              Login
            </Link>
          )}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#3c6897",
        }}
        className="mobiledropdown"
      >
        <Link className="link" style={{ textDecoration: "none" }} to="/">
          <h6>Hem</h6>
        </Link>
        {!currentUser && (
          <Link className="link" style={{ textDecoration: "none" }} to="/apply">
            <h6>Ansök</h6>
          </Link>
        )}
        {currentUser?.role === "user" && (
          <Link className="link" style={{ textDecoration: "none" }} to="/apply">
            <h6>Ansök</h6>
          </Link>
        )}
        <Link className="link" style={{ textDecoration: "none" }} to="/winners">
          <h6>Tidigare pristagare</h6>
        </Link>
        <Link className="link" style={{ textDecoration: "none" }} to="/contact">
          <h6>Kontakt</h6>
        </Link>
        {currentUser && currentUser.role === "admin" && (
          <Link className="link" style={{ textDecoration: "none" }} to="/admin">
            <h6>Adminsida</h6>
          </Link>
        )}
        {currentUser && currentUser.role === "superadmin" && (
          <Link className="link" style={{ textDecoration: "none" }} to="/admin">
            <h6>Adminsida</h6>
          </Link>
        )}
        {currentUser && currentUser.role === "lawyer" && (
          <Link
            className="link"
            style={{ textDecoration: "none" }}
            to="/lawyer"
          >
            <h6>Adminsida</h6>
          </Link>
        )}
        {currentUser && currentUser.role === "reader" && (
          <Link
            className="link"
            style={{ textDecoration: "none" }}
            to="/reader"
          >
            <h6>Adminsida</h6>
          </Link>
        )}
        {currentUser && currentUser.role === "scorer" && (
          <Link className="link" to="/admin">
            Adminsida
          </Link>
        )}
      </div>
    </div>
  );
};

export default Navbar;
