import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

export const ApplyContext = createContext();

export const ApplyContexProvider = ({ children }) => {
  const [applications, setApplications] = useState([]);
  const [updated, setUpdated] = useState(false);

  const setState = (inputs) => {
    setUpdated(!updated);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts/`
        );
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          return;
        }
        const posts = await response.json();
        setApplications(posts);

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [updated]);

  return (
    <ApplyContext.Provider value={{ applications, setState }}>
      {children}
    </ApplyContext.Provider>
  );
};
