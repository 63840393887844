import React from "react";

const ApplicationNavbar = ({ setStep, site }) => {
  const changePage = (e) => {
    e.preventDefault();
    setStep(e.target.value);
  };
  return (
    <>
      <div
        className="applicationnavcontainer"
        style={{
          marginTop: "2px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          style={{
            backgroundColor: site.step == 2 ? "teal" : "grey",
            marginRight: "10px",
          }}
          className="applicationNavButton"
          value={2}
          onClick={changePage}
        >
          Kontaktuppgifter
        </button>
        <button
          style={{
            backgroundColor: site.step == 3 ? "teal" : "grey",
            marginRight: "10px",
          }}
          className="applicationNavButton"
          value={3}
          onClick={changePage}
        >
          Projektdetaljer
        </button>
        <button
          style={{
            backgroundColor: site.step == 4 ? "teal" : "grey",
            marginRight: "10px",
          }}
          className="applicationNavButton"
          value={4}
          onClick={changePage}
        >
          Tidigare bidrag
        </button>
        <button
          style={{
            backgroundColor: site.step == 5 ? "teal" : "grey",
          }}
          className="applicationNavButton"
          value={5}
          onClick={changePage}
        >
          Förhandsgranska
        </button>
      </div>
      <div
      // TODO double display
        className="mobilenavcontainer"
        style={{
          marginTop: "2px",
          display: "flex",
          justifyContent: "space-evenly",
          display: "none",
        }}
      >
        <button
          style={{
            backgroundColor: site.step == 2 ? "teal" : "grey",
            marginRight: "10px",
          }}
          className="applicationNavButton"
          value={2}
          onClick={changePage}
        >
          Kontakt
        </button>
        <button
          style={{
            backgroundColor: site.step == 3 ? "teal" : "grey",
            marginRight: "10px",
          }}
          className="applicationNavButton"
          value={3}
          onClick={changePage}
        >
          Projekt
        </button>
        <button
          style={{
            backgroundColor: site.step == 4 ? "teal" : "grey",
            marginRight: "10px",
          }}
          className="applicationNavButton"
          value={4}
          onClick={changePage}
        >
          Bidrag
        </button>
        <button
          style={{
            backgroundColor: site.step == 5 ? "teal" : "grey",
          }}
          className="applicationNavButton"
          value={5}
          onClick={changePage}
        >
          Bekräfta
        </button>
      </div>
    </>
  );
};

export default ApplicationNavbar;
