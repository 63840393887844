import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import { ApplyContext } from "../context/applyContext";
import { useEffect } from "react";
import Edit from "../img/edit.png";
import PdfDocument from "../components/PdfDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";

const Apply = () => {
  const { currentUser } = useContext(AuthContext) || null;
  const { applications } = useContext(ApplyContext);
  const [myApplication, setMyApplication] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  if (currentUser?.role === "admin") {
    navigate("/admin");
  }

  useEffect(() => {
    async function fetchData() {
      if (currentUser) {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts/uid/${currentUser.id}`
        );
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          return message;
        }
        const posts = await response.json();
        setMyApplication(posts);
        setLoading(false);
        // navigate("/application");
      }
    }
    fetchData();
  }, [currentUser, applications]);

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth() + 1;
    if (month == 11 || month == 12) {
      setActive(true);
    } else if (month == 1 && today.getDate() < 16) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, []);
  const [inputs, setInputs] = useState({
    title: "",
    desc: "",
    uid: "",
    date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
    cat: "",
  });
  const [err, setError] = useState(null);

  // const handleChange = (e) => {
  //   setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setInputs((prev) => ({ ...prev, uid: currentUser.id }));
  //   try {
  //     await axios.post(`/posts/`, inputs);
  //     navigate("/");
  //   } catch (err) {
  //     setError(err.response.data);
  //   }
  // };
  const createPost = async () => {
    if (myApplication) {
      navigate("/application/1");
    } else {
      const today = new Date();
      const month = today.getMonth() + 1;
      let year = today.getFullYear();
      if (month == 11 || 12) {
        year = today.getFullYear() + 1;
      }
      const query = {
        uid: currentUser.id,
        name: currentUser.firstname + " " + currentUser.lastname,
        email: currentUser.email,
        year: year,
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(query),
      });
      const res = await response.json();
      navigate("/application/1");
    }
  };

  return (
    <>
      {!active ? (
        <>
          <div className="home">
            <div className="posts">
              <div className="post">
                <div className="img">
                  <img
                    src="https://www.hedbergsstiftelse.se/media/frontpage.jpg"
                    alt=""
                  />
                </div>
                <div className="content" style={{ textAlign: "center" }}>
                  <h2> Ansökan är stängd för i år </h2>
                  <br />
                  <span>
                    <Link to={"/contact"}>Kontakta oss</Link> om du har frågor
                    rörande hemsidan, ansökningsprocessen eller stiftelsen.{" "}
                    <br />
                  </span>
                  <br />
                  <span>
                    Beslut kring utdelning av stiftelsensmedel beslutas till
                    våren 2024 och nästa ansökningsperiod förväntas börja i
                    november 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* if not logged on */}
          {!currentUser ? (
            <>
              {/* TODO, fix classname to same as home and so on */}
              <div className="Apply">
                <h1>
                  Logga in eller registrerar dig för att göra en ansökan till
                  hedbergs
                </h1>
                <span style={{ textAlign: "center", maxWidth: "500px" }}>
                  Detta är en gemensam ansökningsportal för Hedbergs stiftelse
                  och Norlins stiftelse (norlinsstiftelse.se). <br /> Du ska
                  bara göra en ansökan oavsett om din ansökan avser Hedbergs
                  stiftelse (inflammatoriska sjukdomar), Norlins stiftelse
                  (neurologiska sjukdomar) eller båda. Du behöver INTE precisera
                  vilken stiftelse din ansökan avser.
                </span>
                <span style={{ margin: "5vh" }}>
                  För att registrera dig: <Link to="/register">Registrera</Link>
                </span>
                <span>
                  För att logga in: <Link to="/login">Login</Link>
                </span>
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <div className="Apply">
                  <h1>Laddar...</h1>
                </div>
              ) : (
                <div className="Apply">
                  {/*  if applicaiton has been submitted */}
                  <>
                    <h1> Välkommen:</h1>
                    <p style={{ marginBottom: "5vh", textAlign: "center" }}>
                      <b>
                        {currentUser?.firstname}
                        <br />
                        <br />
                        {myApplication?.title}
                      </b>
                    </p>
                    {myApplication ? (
                      <>
                        {myApplication.completed === true ? (
                          <>
                            <p style={{ marginBottom: "1vh" }}>
                              Tack för din ansökan
                            </p>
                            <p>
                              Vid problem kontakta support@hedbergsstiftelse.se
                            </p>
                            <br />
                            <div className="edit">
                              <PDFDownloadLink
                                style={{
                                  textDecoration: "underline",
                                  color: "black",
                                }}
                                document={
                                  <PdfDocument values={myApplication} />
                                }
                                fileName="AnsökanHedberg.pdf"
                              >
                                {({ _blob, _url, loading, _error }) =>
                                  loading
                                    ? "Loading document..."
                                    : "Laddar ner pdf av din ansökan"
                                }
                              </PDFDownloadLink>{" "}
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <p style={{ marginBottom: "1vh" }}>
                              Redigera din ansökan:
                            </p>
                            <div className="edit">
                              <button
                                style={{
                                  borderStyle: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => navigate("/application/2")}
                              >
                                <img className="applyimg" src={Edit} alt="" />
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        <p style={{ marginBottom: "1vh" }}>Skapa ny Ansökan:</p>
                        <div className="edit">
                          <button
                            style={{ borderStyle: "none", cursor: "pointer" }}
                            onClick={createPost}
                          >
                            <img src={Edit} alt="" />
                          </button>
                        </div>
                      </>
                    )}
                  </>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Apply;
