import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const [stage, setStage] = useState();
  const [updated, setUpdated] = useState(false);
  let storageSupported = false;
  try {
    storageSupported = window.localStorage && true;
  } catch (err) {
    console.log(err);
  }
  useEffect(() => {
    if (storageSupported) {
      setCurrentUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [storageSupported]);

  const login = async (inputs) => {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputs),
    }).catch((error) => {
      console.log(error);
      return;
    });
    const response = await res?.json();
    if (res?.status == 201) {
      setCurrentUser(response);
      return response;
    } else {
      return response;
    }
  };
  const updateUsers = async (inputs) => {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/updateuser`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser.token}`,
        },
        body: JSON.stringify(inputs),
      }
    ).catch((error) => {
      console.log(error);
      return error;
    });
    if (res?.status !== 200) {
      const response = await res.json();
      return response.message;
    }
    if (res?.status == 200) {
      const response = await res.json();
      setCurrentUser((prev) => ({
        ...prev,
        firstname: response.firstname,
        lastname: response.lastname,
      }));
      return response;
    } else {
      return;
    }
  };

  const logout = async (inputs) => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/users/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    setCurrentUser(null);
  };

  const setState = async () => {
    setUpdated(!updated);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/stage/`);
        const response = await res.json();
        setStage(response.stage);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [updated]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{ currentUser, stage, login, logout, setState, updateUsers }}
    >
      {children}
    </AuthContext.Provider>
  );
};
