import React, { useEffect, useState } from "react";
import Table from "../../components/Table";

const Reader = () => {
  const [applications, setApplications] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts/`);
      const posts = await response.json();

      const completedPosts = posts.filter((e) => e.completed === true);
      const approvedPosts = completedPosts.filter((e) => e.approved === true);
      setApplications(approvedPosts);
    };
    fetchData();
  }, []);
  const columns = [
    {
      label: "Namn",
      accessor: "name",
      sortable: true,
    },
    { label: "Titel", accessor: "title", sortable: true },

    { label: "Universitet", accessor: "university", sortable: true },
    { label: "Datum", accessor: "createdAt", sortable: true },
  ];
  return (
    <>
      <div className="table_container">
        <h1>Adminsida</h1>
        {applications ? (
          <>
            <Table
              caption="Ansökningar"
              data={applications}
              columns={columns}
            />
          </>
        ) : (
          <h1>Laddar...</h1>
        )}
      </div>
    </>
  );
};
export default Reader;
