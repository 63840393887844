import React from "react";

function Contact() {
  return (
    <div className="home">
      <div className="posts">
        <div className="post">
          <div className="img">
            <img
              src="https://www.hedbergsstiftelse.se/media/frontpage.jpg"
              alt=""
            />
          </div>
          <div className="content">
            <h2> Kontaktinformation </h2>
            <br />
            Kontakta oss om du har frågor rörande hemsidan, ansökningsprocessen
            eller stiftelsen enligt uppgifter nedan. <br />
            <br />
            <b>Tekniska frågor och support:</b>
            <i>
              Om du t.ex. har frågor om problem på hemsidan, glömt lösenord
              eller annat som rör den tekniska ansökningsprocessen
            </i>
            Webbadministratör: Axel Persson Wieslander <br /> E-mail:
            hedbergsstiftelse@gmail.com
            <br />
            <br />
            <b>Övriga frågor rörande Stiftelsen eller ansökningsprocessen:</b>
            Kontaktperson: Jonas Eilert (Styrelsens ordförande)
            <br /> Telefon: 070 644 16 68
            <br /> E-mail: je@eslaw.se
            <br />
            <br />
            <b>Adress:</b>
            Stiftelsen Apotekare Hedbergs Fond
            <br /> c/o Jonas Eilert
            <br />
            Stadsbudsgatan 12
            <br /> 227 36 Lund
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
