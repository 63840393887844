import React from "react";
import { useState } from "react";
import Info from "../img/info.png";

const InfoHover = ({ origin }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleOnClick = () => {
    setIsHovering(!isHovering);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "0.2rem",
        position: "relative",
      }}
    >
      {isHovering && (
        <div
          style={{
            border: "1px",
            zIndex: "888",
            marginRight: "0.2rem",
            borderColor: "black",
            borderStyle: "solid",
            borderRadius: "5px",
            padding: "10px",
            background: "white",
            fontSize: "14px",
            color: "black",
            height: "auto",
            width: "10rem",
            position: "absolute",
            marginTop: "1.3rem",
            left: "-10rem",
          }}
        >
          <span style={{ position: "relative", zIndex: "888" }}>{origin}</span>
        </div>
      )}
      <img
        style={{ width: 20, height: 20 }}
        src={Info}
        alt=""
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleOnClick}
      />
    </div>
  );
};

export default InfoHover;
